import { environmentConfig } from "@src/appV2/environment";
import { Shift } from "@src/lib/interface/src";
import request from "superagent";

import { IProposeNewRate, RateNegotiationSchema } from "./interfaces";
import { getAuthHeader } from "../superagent";

export const getOpenNegotiatingShifts = async (): Promise<Shift[]> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/workerNegotiatingShifts`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const proposeNewRate = async ({
  shiftId,
  payRate,
  start,
  end,
  negotiationId,
}: IProposeNewRate): Promise<{ negotiation: RateNegotiationSchema }> => {
  if (!shiftId) {
    throw Error("Shift Id is required");
  }

  const body = payRate ? { shiftId, payRate } : { shiftId, start, end };

  return await request
    .patch(`${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/${negotiationId}/propose`)
    .set(await getAuthHeader())
    .send(body)
    .then(({ body }) => body);
};

export const fetchNegotiationById = async (
  negotiationId: string
): Promise<{ negotiation: RateNegotiationSchema }> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/${negotiationId}`)
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const endNegotiation = async (
  negotiationId: string
): Promise<{ negotiation: RateNegotiationSchema }> => {
  return await request
    .patch(`${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/${negotiationId}/reject`)
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const getConflictingNegotiatingShifts = async (shiftId?: string): Promise<number> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/negotiation/conflicting-shifts/${shiftId}`)
    .set(await getAuthHeader())
    .then(({ body }) => body.conflictingShiftsWithNegotiations?.length || 0);
};
