import { LoadingButton, UseModalState } from "@clipboard-health/ui-react";
import { Button, DialogContentText, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { RATE_NEGOTIATION_COMMIT_MODAL } from "@src/constants/modalAlerts";
import { FC } from "react";

import { INegotiationCommitModalProps, NegotiationCommitType } from "./interfaces";

import "./style.scss";

export interface NegotiationCommitModalProps extends INegotiationCommitModalProps {
  modalState: UseModalState;
}

export const NegotiationCommitModal: FC<NegotiationCommitModalProps> = ({
  modalState,
  rate,
  formattedTimeText,
  onCommit,
  closeModal,
  modalType,
  requestLoading,
}) => {
  let modalText;

  if (modalType === NegotiationCommitType.PROPOSE_NEW_RATE) {
    modalText = RATE_NEGOTIATION_COMMIT_MODAL[modalType].CONTENT(rate);
  } else if (modalType === NegotiationCommitType.PROPOSE_NEW_TIME) {
    modalText = RATE_NEGOTIATION_COMMIT_MODAL[modalType].CONTENT(formattedTimeText);
  } else {
    modalText = RATE_NEGOTIATION_COMMIT_MODAL[modalType].CONTENT;
  }

  return (
    <CommonDialog
      modalState={modalState}
      title={RATE_NEGOTIATION_COMMIT_MODAL[modalType].TITLE}
      actions={
        <Stack width="100%" spacing={1}>
          <LoadingButton
            variant="contained"
            color={modalType === NegotiationCommitType.END_NEGOTIATION ? "error" : "primary"}
            fullWidth
            isLoading={requestLoading}
            onClick={onCommit}
          >
            {RATE_NEGOTIATION_COMMIT_MODAL[modalType].BUTTON_TEXT}
          </LoadingButton>

          <Button variant="outlined" color="secondary" fullWidth onClick={closeModal}>
            Cancel
          </Button>
        </Stack>
      }
    >
      <DialogContentText textAlign="center" paragraph>
        {modalText}
      </DialogContentText>
      <DialogContentText textAlign="center" paragraph>
        {RATE_NEGOTIATION_COMMIT_MODAL[modalType].SUB_CONTENT}
      </DialogContentText>
    </CommonDialog>
  );
};
