import isString from "lodash/isString";

import { GetUrgentShiftsSegmentPayloadParams, UrgentShiftsSegmentTrackingFields } from "./model";

const NOTIFICATION_MESSAGE_TYPE_ZENDESK = "zd:agentWorkspace";

export const isZendeskNotification = (payload: any): boolean => {
  if (
    payload?.data?.message?.source?.type === NOTIFICATION_MESSAGE_TYPE_ZENDESK ||
    (isString(payload?.data?.message) &&
      payload?.data?.message.includes(NOTIFICATION_MESSAGE_TYPE_ZENDESK))
  ) {
    return true;
  }
  return false;
};

export const removeUndefinedValues = <T extends Record<string, unknown>>(data: T): Partial<T> => {
  const obj = { ...data };

  Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));

  return obj;
};

export const getUrgentShiftsSegmentPayload = (
  data: GetUrgentShiftsSegmentPayloadParams
): UrgentShiftsSegmentTrackingFields => {
  if (!["LATE_URGENT_SHIFTS", "NCNS_URGENT_SHIFTS"].includes(data.method)) {
    return {};
  }

  const { shiftId, shiftIds, workerId, notificationType, count, earnings, createdAt } = data;

  const shiftIdsSeparated = shiftIds?.split(";").map((shiftId) => shiftId.trim());

  return removeUndefinedValues({
    shiftId,
    shiftIds: shiftIdsSeparated,
    workerId,
    notificationType,
    count,
    earnings,
    createdAt,
  });
};
