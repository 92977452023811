import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const updatePushToken = async (token: string): Promise<void> => {
  try {
    await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/pushToken`)
      .set(await getAuthHeader())
      .send({ token });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const recordImpression = async (messageId: string, channel: string): Promise<void> => {
  try {
    await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/notificationInteraction/record`)
      .set(await getAuthHeader())
      .send({ messageId, channel });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { updatePushToken, recordImpression };
